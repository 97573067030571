import React from "react"
import Grid from "@material-ui/core/Grid"

import "./PricingTableSupport.scss"

import Section from "../../components/section"
import SectionInner from "../../components/section-inner/section-inner"
import PricingTableSupportItem from "../../components/pricing-table-support-item"

const PricingTableSupport = ({ title, description, pricingTableItems }) => {

  return (
    <Section id="PricingSupport" className="PricingTableSupport Section--vPadding">
      <SectionInner className="PricingTableSupport-inner u-textCenter">
        {
          title &&
          <h2 className="Section-title t-h2">{title}</h2>
        }
        {
          description &&
          <div className="Section-text t-body2" dangerouslySetInnerHTML={{ __html: description }}/>
        }

        <Grid className="PricingTableSupport-tableGrid" container justify="center" spacing={0}>
          {pricingTableItems.map((item, index) => {
            return <PricingTableSupportItem key={index}
                                            title={item.title}
                                            icon={item.icon ? (item.icon.imageFile ? item.icon.imageFile.publicURL : item.icon.sourceUrl) : null}
                                            listInfos={item.listInfos}
                                            price={item.price}
            />
          })}
        </Grid>
      </SectionInner>
    </Section>
  )
}

export default PricingTableSupport
