import React from "react"

import "../pricing-table-item/pricing-table-item.scss"

import IconButton from "@material-ui/core/IconButton"
import InfoIcon from "../../assets/svg/icons/info.svg"
import ActionButton from "../action-button"
import Grid from "@material-ui/core/Grid"
import { withTranslation } from "react-i18next"
import { Paper } from "@material-ui/core"
import { useDialog } from "../../hooks/useDialog"
import { ORDER_SUPPORT } from "../../stepper/orderTypes"
import ProductStepper from "../product-stepper/product-stepper"
import { connect } from "react-redux"
import { setOrderType } from "../../state/actions"

const PricingTableSupportItem = ({ t, key, title, price, icon, listInfos, setOrderType }) => {

  const isFree = !price

  const {handleDialogOpen} = useDialog()


  const handleActionButtonClick = (event) => {
    event.preventDefault()

    setOrderType(ORDER_SUPPORT)

    handleDialogOpen(
      t("Bestellung") + ": TRNSYS 18 Support",
      <ProductStepper />,
      false
    )
  }

  return (
    <Grid item key={key} className="PricingTableItem" md={12} lg={6}>
      <Paper className="PricingTableItem-paper" elevation={20}>

        <div className="PricingTableItem-top">
          {
            icon &&
            <img src={icon} className="PricingTableItem-image u-marginCenter" alt={icon.altText}/>
          }
          <div className="u-flex u-justifyCenter u-alignCenter">
            <div className="PricingTableItem-title">
              <h3 className="t-h2">{title}</h3>
            </div>

          </div>
          <ul className="PricingTableItem-list">
            {listInfos.map((listInfo, index) => (
              <li key={index}>{listInfo.listInfo}
                {listInfo.listInfoInfoText &&
                <IconButton href={null} className="PricingTableItem-info c-accent-light" size="medium"
                            onClick={() => handleDialogOpen(listInfo.listInfo, listInfo.listInfoInfoText)}>
                  <InfoIcon/>
                </IconButton>
                }
              </li>
            ))}
          </ul>
        </div>
        <div className="PricingTableItem-bottom">
          <p className="PricingTableItem-price t-body1">
            {
              !isFree && price + " €"
            }
          </p>
          {
            !isFree &&
            <ActionButton className="PricingTableItem-actionButton"
                          onClick={handleActionButtonClick}
                          variant={"contained"}
                          title={t("Bestellen")}>{t("Bestellen")}</ActionButton>
          }
        </div>
      </Paper>

    </Grid>
  )
}

const mapDispatchToProps = {
  setOrderType,
}

export default withTranslation()(connect(null, mapDispatchToProps)(PricingTableSupportItem))
